.list-content-header {
    display: flex;
    flex-direction: column;
    /* Change to column layout */
    align-items: flex-start;
    /* Align items to the start */
    margin-bottom: 15px;
    gap: 10px;
    /* Add some space between the button and search bar */
}

.list-content-horizontal-header {
    display: flex;
    flex-direction: row;
    /* Change to column layout */
    align-items: flex-start;
    /* Align items to the start */
    margin-bottom: 15px;
    gap: 10px;
    /* Add some space between the button and search bar */
}

.list-main-content {
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    max-width: 1200px;
    /* Optional: to limit the content width */
    margin: 0 auto;
    /* Center the content if you limit the width */
}

.button-container {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    /* Ensure the button container takes full width */
}

.add-data-button {
    display: flex;
    align-items: center;
    padding: 6px 12px;
    font-size: 14px;
    color: white;
    background-color: #4CAF50;
    border-radius: 4px;
    transition: background-color 0.3s ease;
    cursor: pointer;
}

.add-data-button .icon {
    margin-right: 8px;
    /* Space between icon and text */
    font-size: 16px;
}

.add-data-button:hover {
    background-color: #45a049;
    /* Lighter green on hover */
}

.sort-controls {
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: flex-end;
    padding-bottom: 20px;
}

.sort-controls label {
    font-weight: bold;
    font-size: 14px;
}

.sort-controls select,
.sort-controls button {
    padding: 4px 8px;
    /* Adjust padding to control height */
    font-size: 12px;
    /* Adjust font-size for text */
    height: 24px;
    min-width: 80px;
    border: 1px solid #ccc;
    border-radius: 1px;
    cursor: pointer;
}

.sort-controls select {
    width: 100px;
    /* Fixed width for select */
}

.sort-controls button {
    width: 100px;
    /* Fixed width for button */
}

.toolbar {
    width: 100%;
    margin-top: 10px;
}

.search-bar {
    width: 100%;
    max-width: 500px;
    padding: 10px;
    border-radius: 5px;
    box-sizing: border-box;
    /* Make sure padding is inside width */
}

/* Tabs */
.tabs {
    margin-bottom: 20px;
}

.tabs button {
    background-color: #75b7d0;
    /* Light blue background */
    color: #ffffff;
    /* White text */
    border: none;
    padding: 8px 16px;
    cursor: pointer;
    margin-right: 5px;
    font-size: 16px;
}

.tabs button.active {
    background-color: #3982b0;
    /* Medium blue for active tab */
}

.tabs button:focus {
    outline: none;
}

/* Secondary tabs */
.secondary-tabs button {
    background-color: #223f91;
    /* Softer green background */
    color: #ffffff;
    border: none;
    padding: 8px 16px;
    border-radius: 20px;
    cursor: pointer;
    margin-right: 5px;
    font-size: 14px;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.secondary-tabs button:hover {
    background-color: #223f91;
    /* Darker green on hover */
    transform: scale(1.05);
    /* Slight scale on hover */
}

.secondary-tabs button.active {
    background-color: #3e6dec;
    /* Bright pink for active tab */
    color: #ffffff;
    border-radius: 20px;
}

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    font-size: 16px;
    gap: 5px;
}

.pagination button {
    background-color: #75b7d0;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 8px 12px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.pagination button.active {
    background-color: #3982b0;
    /* Different color for active page */
    font-weight: bold;
}

.pagination button:disabled {
    background-color: #d0d0d0;
    color: #888;
    cursor: not-allowed;
}

.pagination button:not(:disabled):hover {
    background-color: #75b7d0;
}

.pagination span {
    font-weight: bold;
    color: #555;
}