/* Global Styles */
body {
  font-family: Arial, sans-serif;
  background-color: #e4ebe0;
  margin: 0;
  padding: 0;
  color: #263f60;
}

h1,
h2 {
  color: #263f60;
}

a {
  color: #007bff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

button {
  background-color: #75b7d0;
  color: #fff;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  font-size: 16px;
}

button:focus {
  outline: none;
}

.error {
  color: #d9534f;
  background-color: #f2dede;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 15px;
  border: 1px solid #ebccd1;
}