.formulation-detail {
    /* Container styling */
    max-width: 1200px;
    margin: 0 auto;
    padding: 1rem;
    color: #333;
    font-family: 'Helvetica Neue', Arial, sans-serif;
}

.formulation-detail__body {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-top: 0.3rem;
}

.formulation-detail__column {
    /* In case you want multiple columns or advanced layout later */
    flex: 1;
}

.formulation-detail__section {
    margin-bottom: 2rem;
}

.formulation-detail__section-title {
    font-size: 1.125rem;
    font-weight: 600;
    margin-bottom: 1rem;
    color: #444;
}

/* Material list styles */
.formulation-detail__material-list {
    list-style: none;
    margin: 0;
    padding: 0;
}

.formulation-detail__material-item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.25rem 0;
    border-bottom: 1px solid #eee;
}

.formulation-detail__material-item:last-child {
    border-bottom: none;
}

.formulation-detail__material-link {
    margin-right: 0.5rem;
    text-decoration: none;
    color: #0069d9;
}

.formulation-detail__material-link:hover {
    text-decoration: underline;
}

.formulation-detail__material-name {
    font-weight: 600;
    color: #333;
}

.formulation-detail__material-model {
    color: #555;
    font-size: 0.875rem;
    margin-right: auto;
}

.formulation-detail__material-ratio {
    font-weight: 600;
    font-size: 0.875rem;
    color: #333;
}

/* Related work orders section */
.formulation-detail__related-works {
    background-color: #fafafa;
    padding: 1rem;
    border-radius: 4px;
}

.formulation-detail__workorder-list {
    list-style: none;
    margin: 0;
    padding: 0;
}

.formulation-detail__workorder-item {
    padding: 0.5rem 0;
    border-bottom: 1px solid #eee;
}

.formulation-detail__workorder-item:last-child {
    border-bottom: none;
}

.formulation-detail__workorder-name {
    color: #333;
    font-weight: 500;
}