.formulation-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: flex-start;
    justify-content: center;
    z-index: 1000;
}

.formulation-modal-content {
    position: relative;
    margin-top: 50px;
    /* Adjust this top margin to control how far from the top you want it */
    width: 700px;
    /* Fixed or semi-fixed width */
    max-width: 90%;
    /* Resizes on smaller screens */
    max-height: 80vh;
    /* Prevents it from growing too tall */
    overflow-y: auto;
    /* Enables vertical scroll if content overflows */
    background-color: #fff;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
}

.formulation-modal-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    font-size: 14px;
    /* Smaller text size */
}

.formulation-modal-table th {
    padding: 8px;
    text-align: left;
    background-color: #007bff;
    /* Distinct header background */
    color: white;
    /* White text for contrast */
    position: sticky;
    top: 0;
    z-index: 1;
}

.formulation-modal-table td {
    padding: 8px;
    text-align: left;
    background-color: #f9f9f9;
    /* Light background for readability */
    border-bottom: 1px solid #ddd;
}

.formulation-modal-table tr:nth-child(even) td {
    background-color: #f1f1f1;
    /* Alternate row background */
}

.formulation-modal-close-button {
    margin-top: 10px;
    padding: 6px 10px;
    /* Smaller padding */
    background: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    display: block;
    margin-left: auto;
    font-size: 12px;
    /* Smaller text size */
}

.formulation-modal-select-button {
    padding: 4px 8px;
    /* Smaller button size */
    font-size: 12px;
    /* Smaller text size */
    background-color: #28a745;
    /* Green button */
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.formulation-modal-select-button:hover {
    background-color: #218838;
    /* Darker green on hover */
}