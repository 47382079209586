/* General Header Styles */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 32px;
  background-color: #f8f9fa;
  border-bottom: 1px solid #dee2e6;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Logo Section */
.header-logo-section {
  flex-shrink: 0;
}

.header-logo {
  font-size: 18px;
  /* Reduce font size */
  font-weight: bold;
  color: #343a40;
  margin: 0;
  padding: 0;
}

.logo-image {
  height: 35px;
}

/* Navigation Section */
.header-nav {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  justify-content: flex-end;
  /* Aligns the navigation to the right */
}

.header-nav-list {
  list-style: none;
  display: flex;
  gap: 24px;
  padding: 0;
  margin: 0;
  margin-top: 15px;
  /* Add margin-top to move it lower */
}

/* .header-nav-item {
} */
.header-logo-button {
  background-color: transparent;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
}

.header-nav-button {
  font-size: 16px;
  background-color: transparent;
  color: #007bff;
  border: none;
  cursor: pointer;
  padding: 8px 20px;
  transition: color 0.3s ease;
}

.header-nav-button:hover {
  color: #0056b3;
  text-decoration: underline;
}

.header-nav-button.active {
  background-color: #4a6dc7;
  /* Highlight color */
  color: white;
}

/* Button Styles */
.logout-button {
  background-color: transparent;
  /* Transparent background for outline style */
  color: #75b7d0;
  /* Light blue text */
  border: 2px solid #75b7d0;
  /* Light blue border */
  padding: 8px 10px;
  cursor: pointer;
  font-size: 14px;
  border-radius: 4px;
  /* Optional: rounded corners */
}

.logtout-button:hover {
  background-color: #75b7d0;
  /* Light blue background on hover */
  color: #ffffff;
  /* White text on hover */
}

.logtout-button:focus {
  outline: none;
}

/* User Menu */
.user-menu {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-icon-button {
  background-color: #4a6dc7;
  /* Matching the active state color for consistency */
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 18px;
  /* Slightly smaller font size */
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.user-icon-button:hover {
  background-color: #0056b3;
  /* Darker blue on hover */
  transform: scale(1.1);
  /* Subtle scaling effect */
}

.user-menu-dropdown {
  position: absolute;
  right: 0;
  top: calc(100% + 10px);
  background-color: white;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
  list-style: none;
  padding: 0;
  margin: 0;
  width: 140px;
  z-index: 1000;
}

.user-menu-dropdown li {
  padding: 5px 10px;
  cursor: pointer;
  text-align: left;
  font-size: 14px;
  color: #343a40;
  transition: background-color 0.3s ease, color 0.3s ease;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0;
  box-sizing: border-box;

}

.user-menu-dropdown li:hover {
  background-color: #f8f9fa;
  color: #007bff;
}

.user-menu-dropdown li:not(:last-child) {
  border-bottom: 1px solid #dee2e6;
  /* Separator between items */
}