/* Table Styles */
table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    overflow: visible;
}

table th,
table td {
    padding: 12px;
    border: 1px solid #3982b0;
    text-align: left;
    position: relative;
}

table th {
    background-color: #75b7d0;
    color: #fff;
}

table tbody tr:nth-child(even) {
    background-color: #f2f2f2;
}

/* -- FORMULATION TABLE STYLES -- */
/* General Table Styling */
.formulation-list-table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    font-size: 0.95rem;
    color: #2c3e50;
}

/* Table Header */
.formulation-list-table thead {
    background-color: #f8f9fa;
    border-bottom: 2px solid #dfe6e9;
}

.formulation-list-table thead th {
    padding: 14px 12px;
    text-align: left;
    font-weight: 700;
    color: #34495e;
    cursor: pointer;
    position: relative;
    transition: background 0.3s ease-in-out;
}

.formulation-list-table thead th:hover {
    background-color: #e9ecef;
}

/* Sorting Icon Styling */
.formulation-list-table thead th::after {
    content: ' •';
    color: #a3a3a3;
    font-weight: normal;
    margin-left: 5px;
}

.formulation-list-table thead th.sorted-asc::after {
    content: ' ▲';
    color: #007bff;
}

.formulation-list-table thead th.sorted-desc::after {
    content: ' ▼';
    color: #007bff;
}

/* Table Body */
.formulation-list-table tbody tr {
    background-color: #ffffff;
    transition: background 0.2s ease-in-out;
}

.formulation-list-table tbody tr:nth-child(even) {
    background-color: #f9fafb;
}

.formulation-list-table tbody tr:hover {
    background-color: #e3f2fd;
}

/* Table Cells */
.formulation-list-table td {
    padding: 12px 10px;
    border-bottom: 1px solid #e0e0e0;
    vertical-align: top;
}

/* Formulation Cell Container */
.formulation-cell {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

/* Material Details (as a Bullet List) */
.formulation-details {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding-left: 16px;
}

.formulation-details div {
    font-size: 0.9rem;
    color: #34495e;
    position: relative;
}

/* Bullet Style */
.formulation-details div::before {
    content: '•';
    color: #007bff;
    font-weight: bold;
    display: inline-block;
    width: 12px;
    margin-left: -16px;
    margin-right: 6px;
}

/* Sum Ratio Highlight */
.sum-ratio {
    display: inline-block;
    background-color: #d4edda;
    border-radius: 4px;
    padding: 4px 8px;
    font-weight: bold;
    color: #155724;
    font-size: 0.85rem;
}

/* Links Inside Table */
.formulation-list-table td a {
    color: #007bff;
    font-weight: 600;
    text-decoration: none;
    transition: color 0.2s ease-in-out;
}

.formulation-list-table td a:hover {
    text-decoration: underline;
    color: #091b2e;
}

/* Hover effect for rows */
table tbody tr:hover {
    background-color: #e0f2f9;
}

/* Adjust column and row spacing */
table td {
    vertical-align: top;
    /* Align text to the top of the cell */
}

/* Remove bullet points if needed */
ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.flag-column {
    width: 30px;
    text-align: center;
}

.flag-column svg {
    vertical-align: middle;
}