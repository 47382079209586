/* Style for the form group */
.form-group {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
}

/* Style for the field label */
.field-label {
  font-weight: bold;
  display: block;
  margin-bottom: 8px;
}

/* Style for the required indicator */
.required-indicator {
  color: red;
  /* Red color for required field indicator */
  margin-left: 0.25rem;
  /* Adds space between label text and asterisk */
}

/* Style for the field explanation */
.field-explanation {
  font-size: 11px;
  /* Makes the text slightly smaller */
  color: #666;
  /* Lighter font color for explanation text */
  display: block;
  /* Ensures it takes a new line */
  margin-bottom: 0.5rem;
  /* Adds space between explanation and input/select */
}

/* Optional styles for input and select fields */
.field-input,
.field-select {
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 300px;
  padding: 10px;
  font-size: 13px;
  box-sizing: border-box;
  /* Ensures padding and border are included in width */
  height: flex;
  /* Set a fixed height to normalize select and input */
  line-height: 1.2;
  /* Consistent line-height */
}

/* Container for the form */
.form-container {
  margin: 40px auto 0;
  padding: 20px;
  max-width: 900px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.form-title {
  font-size: 20px;
  font-weight: bold;
  text-align: left;
  color: #333;
  margin-bottom: 20px;
  text-transform: capitalize;
  letter-spacing: 1px;
  padding-bottom: 10px;
  background: linear-gradient(90deg, #4a90e2, #50b3a2);
  color: white;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

/* Style for the submit button */
.submit-button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 18px;
}

/* Hover effect for the submit button */
.submit-button:hover {
  background-color: #45a049;
  /* Slightly darker green on hover */
}

/*newnew*/
.form-page {
  display: flex;
  flex-direction: column;
  padding: 40px;
}

.form-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
}

.raw-material-form {
  flex: 0 0 30%;
  margin-right: 10px;
  padding: 10px;
  box-sizing: border-box;
}

.file-upload-preview {
  flex: 0 0 70%;
  margin-left: 10px;
  padding: 10px;
  box-sizing: border-box;
}

.file-preview {
  margin-top: 30px;
  border: 1px solid #ccc;
  padding: 10px;
  background-color: #f9f9f9;
  max-height: 400px;
  overflow-y: auto;
}

/* Card and Section Styles */
.form-card {
  display: flex;
  flex-direction: column;
  gap: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  flex: 1;
}