.detail-page {
    display: flex;
    flex-direction: column;
    padding: 40px;
}

.header-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
}

.header-top-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
}

.header-title {
    font-size: 1.8em;
    font-weight: bold;
}

.header-meta {
    font-size: 0.9em;
    color: #666;
}

/* New button group styling */
.header-button-group {
    display: flex;
    gap: 10px;
    /* Space between buttons */
}

.header-button-group button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    font-size: 1em;
    border: none;
    background-color: #165b2b;
    cursor: pointer;
    transition: background-color 0.3s;
    border-radius: 2px;
}

.header-button-group button:hover {
    background-color: #165b2b73;
}

.content-section {
    display: flex;
    gap: 20px;
    align-items: flex-start;
}

.left-column,
.right-column {
    flex: 1;
    max-height: calc(100vh - 200px);
    overflow-y: auto;
    padding: 10px;
    background: #f8f8f8;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.left-column .table-section {
    margin-bottom: 20px;
}

.left-column table {
    width: 100%;
    border-collapse: collapse;
    font-size: 14px;
    table-layout: fixed;
}

.left-column table th {
    background-color: #322a2a;
    color: #fff;
    text-align: center;
    padding: 4px;
    border: 1px solid #ccc;
}

.left-column table td {
    background-color: #f8f3f3;
    color: #000;
    padding: 4px;
    border: 1px solid #ccc;
}

/* First column (field name) alignment */
.left-column table td:first-child {
    text-align: right;
    background-color: #453030;
    font-weight: bold;
    color: #fff;
    padding: 8px;
}

/* Second column (field value) alignment */
.left-column table td:nth-child(2) {
    text-align: left;
    padding: 8px;
}

.left-column table td:nth-child(3) {
    text-align: left;
    padding: 8px;
}

.left-column .related-data-section {
    padding: 10px;
    background-color: #f0f0f0;
    border-radius: 8px;
}

.related-data-section h3 {
    margin-bottom: 10px;
    font-size: 1.2em;
}

.related-data-section ul {
    list-style: none;
    padding: 0;
}

.related-data-section ul li {
    margin-bottom: 8px;
}

.related-data-section ul li a {
    text-decoration: none;
    color: #2c69ab;
}

.related-data-section ul li a:hover {
    text-decoration: underline;
}

.right-column img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
}

.info-icon {
    margin-left: 5px;
    color: #888;
    cursor: pointer;
    position: relative;
}

.info-icon:hover {
    color: #333;
}

.info-icon:hover::after {
    content: attr(data-tooltip);
    position: absolute;
    bottom: 100%;
    left: 0;
    background-color: #333;
    color: #fff;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 12px;
    white-space: nowrap;
    z-index: 999;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
    transform: translateX(calc(min(0px, 50% - 100px)));
}

[data-tooltip] {
    position: relative;
}

.file-section img.uploaded-image {
    width: 100%;
    max-width: 600px;
    height: auto;
    object-fit: contain;
}

.file-section embed.uploaded-pdf {
    width: 100%;
    max-width: 600px;
    height: 800px;
    margin-top: 10px;
    object-fit: contain;
}

@media (max-width: 768px) {
    .uploaded-image {
        max-width: 300px;
    }

    .uploaded-pdf {
        max-width: 300px;
        height: 400px;
    }
}

/* Other Elements in Formulation Detail Page*/
.material-link {
    color: #007bff;
    text-decoration: none;
}

.material-link:hover {
    text-decoration: underline;
}

.material-name {
    flex: 2;
}

.material-model {
    margin-left: 20px;
}

.material-ratio {
    margin-left: auto;
    font-weight: bold;
}

/* Toggle switch to show version */
.toggle-container {
    display: flex;
    align-items: center;
    margin: 0.5rem 0;
}

.toggle-description {
    margin-left: 8px;
    font-size: 0.9rem;
    color: #333;
}

.toggle-switch {
    position: relative;
    display: inline-block;
    width: 34px;
    height: 18px;
    margin: 0.5rem 0;
}

.toggle-switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 18px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
}

input:checked+.slider {
    background-color: #007BFF;
}

input:checked+.slider:before {
    transform: translateX(16px);
}

input:disabled+.slider {
    background-color: #ddd;
    cursor: not-allowed;
}