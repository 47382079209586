/* Ensure the root div takes up the full viewport */
html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow-x: hidden;
}

.main-page {
  display: flex;
  height: 100vh;
  width: 100vw;
}

/* Sidebar */
.sidebar {
  width: 260px;
  padding: 15px;
  background-color: #f8f8f8;
  border-right: 1px solid #ddd;
  color: #333;
  font-family: Arial, sans-serif;
  overflow-y: auto;
  max-height: 100vh;
}

/* Main Content */
.main-content {
  flex-grow: 1;
  padding: 20px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}

.main-data-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  margin-top: 0;
  margin-bottom: 0;
  padding-bottom: 100px;
  width: 100%;
}

.main-data-card {
  padding: 15px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
}

/* Ensure title does not overlap with tags */
.main-data-title {
  font-weight: bold;
  color: #333;
  text-decoration: none;
  display: block;
  margin-top: 40px;
}

.main-data-metadata {
  font-size: 0.9em;
  color: #777;
}

/* Tags container to hold status and data type tags side by side */
.tag-container {
  display: flex;
  gap: 10px;
  position: absolute;
  top: 10px;
  right: 10px;
}

/* Styling for each tag */
/* Common styling for both tags */
.main-status-tag,
.main-data-type-tag {
  font-size: 0.8em;
  color: #fff;
  padding: 2px 5px;
  border-radius: 4px;
  white-space: nowrap;
}

/* Colors for status tags */
.main-status-tag.updated {
  background-color: #4a90e2;
  /* Blue for Updated */
}

.main-status-tag.new {
  background-color: #28a745;
  /* Green for New */
}

.main-data-type-tag {
  background-color: #c67d09;
  /* Green color for data type tag */
}

.platform-updates,
.resources {
  margin-bottom: 20px;
}

.platform-updates h2,
.resources h2 {
  font-size: 1.2em;
  color: #0f2e51;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.update-list,
.resource-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.update-item {
  padding: 8px 0;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.2s;
}

.update-item:hover {
  background-color: #f0f4fa;
}

.update-title {
  font-weight: bold;
  font-size: 0.85em;
  color: #333;
}

.update-date {
  font-size: 0.6em;
  color: #777;
}

.resource-list li {
  margin: 10px 0;
}

.resource-list a {
  display: flex;
  align-items: center;
  color: #007bff;
  text-decoration: none;
  font-weight: 500;
}

.resource-list a:hover {
  color: #0056b3;
  text-decoration: underline;
}

.zoom-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.zoom-modal-content {
  position: relative;
  max-width: 90%;
  max-height: 90%;
}

.zoom-modal-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: white;
  font-size: 1.5em;
  cursor: pointer;
}