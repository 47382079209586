.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.margin-bottom {
  margin-bottom: 20px;
}

.padding {
  padding: 10px;
}

.back-button-container {
  display: flex;
  justify-content: flex-end;
}

.back-button {
  color: #fff;
  padding: 8px 12px;
  border: none;
  border-radius: 0.5px;
  cursor: pointer;
}

.back-button:hover {
  background-color: #75b8d094;
}