.floating-button-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
}

.floating-button {
    background-color: #37a6a0;
    color: white;
    border: none;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    font-size: 20px;
    cursor: pointer;
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.floating-button:hover {
    background-color: #90cdd0;
    /* Darken the button on hover */
    transform: scale(1.05);
    /* Slightly enlarge on hover */
}

.floating-menu {
    position: absolute;
    bottom: 80px;
    /* Adjust the position based on button size */
    right: 0;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
    /* Add shadow to the menu */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* Align menu items to the left */
    min-width: 200px;
    /* Ensure enough space for the text */
}

.menu-item,
.floating-menu a {
    display: block;
    /* Make both behave like block elements */
    width: 100%;
    /* Make buttons and links take the full width of the menu */
    padding: 10px 15px;
    text-align: left;
    text-decoration: none;
    background-color: #90cdd0;
    /* Button color */
    color: white;
    border: none;
    border-radius: 4px;
    margin-bottom: 8px;
    /* Space between items */
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    font-size: 16px;
    /* Ensure font size is the same */
    line-height: 1.5;
    /* Ensure consistent line height */
    box-sizing: border-box;
}

.menu-item:hover,
.floating-menu a:hover {
    background-color: #37a6a0;
    /* Darken on hover */
    transform: translateY(-2px);
    /* Lift effect on hover */
}

.floating-menu a {
    display: block;
}

.menu-item:last-child,
.floating-menu a:last-child {
    margin-bottom: 0;
    /* Remove the bottom margin on the last item */
}




/*Update Popup*/
.modal-overlay-info {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content-info {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    width: 90%;
    max-width: 800px;
    max-height: 80vh;
    overflow-y: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 1001;
}

.modal-header {
    margin-bottom: 20px;
}

.modal-description {
    margin-bottom: 20px;
}

.modal-image img {
    max-width: 100%;
    height: auto;
    max-height: 500px;
    object-fit: cover;
    display: block;
    margin: 0 auto;
}

.update-modal-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 16px;
    cursor: pointer;
    color: #888;
    padding: 5px;
    z-index: 1002;
}

.update-modal-close-button:hover {
    color: black;
}