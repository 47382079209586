/* General Content Wrapper */
.content-wrapper {
    padding: 20px;
    overflow: hidden;
}

/* Tables Wrapper for Horizontal Scroll */
.tables-wrapper {
    display: flex;
    flex-wrap: nowrap;
    gap: 16px;
    /* Adjusted gap to fit the card padding */
    overflow-x: auto;
    overflow-y: hidden;
    padding: 10px 0;
    /* Padding for better spacing inside the card */
    scrollbar-color: #c1c1c1 transparent;
    scrollbar-width: thin;
}

/* Pretty Scrollbar for Webkit Browsers */
.tables-wrapper::-webkit-scrollbar {
    height: 8px;
}

.tables-wrapper::-webkit-scrollbar-track {
    background: #f4f4f4;
    border-radius: 4px;
}

.tables-wrapper::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
    border: 2px solid #f4f4f4;
}

/* Important Section Styling */
.important-section {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Error Modal */
.error-modal {
    background-color: #f8d7da;
    color: #842029;
    padding: 16px;
    border-radius: 8px;
    margin-bottom: 20px;
}

/* Three Column Layout */
.three-column-layout {
    display: flex;
    gap: 20px;
    padding: 20px;
    overflow: auto;
    /* Enable scrollbar for the entire page */
    height: 100vh;
    /* Full viewport height */
    box-sizing: border-box;
}

/* Individual Columns */
/* Individual Columns with Different Minimum Widths */
.column {
    flex: 1;
    /* Columns take equal remaining space */
    display: flex;
    flex-direction: column;
    gap: 20px;
}

/* Specific Minimum Widths for Each Column */
.column-1 {
    min-width: 700px;
    /* Column 1 has a minimum width of 300px */
}

.column-2 {
    min-width: 400px;
    /* Column 2 has a minimum width of 400px */
}

.column-3 {
    min-width: 350px;
    /* Column 3 has a minimum width of 350px */
}


/* Pretty Scrollbar for Entire Page */
.three-column-layout::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

.three-column-layout::-webkit-scrollbar-track {
    background: #f4f4f4;
    border-radius: 4px;
}

.three-column-layout::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
    border: 2px solid #f4f4f4;
}

/* Card Style */
.card {
    padding: 16px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.card h2 {
    font-size: 18px;
    /* Slightly larger for a clear section header */
    font-weight: 600;
    /* Semi-bold for emphasis */
    color: #333;
    /* Dark gray to match a professional tone */
    margin-bottom: 12px;
    /* Space below for better separation */
    text-align: left;
    /* Align with the card content */
}

.card h4 {
    font-size: 16px;
    /* Slightly smaller than h2 */
    font-weight: 500;
    /* Medium weight for distinction */
    color: #555;
    /* Lighter gray for less emphasis */
    margin: 8px;
    /* Less spacing than h2 */
    text-align: left;


    /* Consistent alignment */
}

.formulation-header {
    display: flex;
    justify-content: flex-start;
    /* Aligns the button to the left */
    margin-bottom: 1px;
    /* Adds spacing between the button and the tables */
}

.select-formulation-button {
    padding: 8px 12px;
    /* Smaller padding to fit well with the card style */
    font-size: 14px;
    font-weight: 500;
    color: #ffffff;
    background-color: #007bff99;
    /* Primary button color */
    border: none;
    border-radius: 6px;
    /* Rounded corners to match the card's style */
    cursor: pointer;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s, transform 0.2s;
}

.select-formulation-button:hover {
    background-color: #0057b399;
    /* Darker blue on hover */
    transform: translateY(-1px);
    /* Subtle lift effect */
}

.select-formulation-button:active {
    background-color: #00408591;
    /* Darkest blue on active state */
    transform: translateY(0);
    /* Reset lift */
}

/* Save Button */
.save-button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.save-button:hover {
    background-color: #0056b3;
}

/* Modal Overlay */
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

/* Modal Content */
.popup-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    max-width: 90%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.popup-content h2 {
    margin-bottom: 20px;
    font-size: 18px;
}

.popup-content pre {
    background: #f5f5f5;
    padding: 10px;
    border-radius: 4px;
    text-align: left;
    overflow: auto;
    max-height: 300px;
}

.close-button {
    margin-top: 20px;
    padding: 8px 16px;
    background-color: #dc3545;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.close-button:hover {
    background-color: #c82333;
}

.excel-preview table {
    width: 100%;
    border-collapse: collapse;
    font-family: Arial, sans-serif;
}

.excel-preview th,
.excel-preview td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: left;
}

.excel-preview th {
    background-color: #f2f2f2;
}