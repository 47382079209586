.tile {
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
}

.tile:hover {
  transform: translateY(-5px);
}

/* Make platform update tile span across all columns */
.platform-updates {
  grid-column: 1 / -1;
}

h2 {
  font-size: 20px;
  margin-bottom: 10px;
}

a {
  color: #007BFF;
  text-decoration: none;
  font-size: 16px;
}

a:hover {
  text-decoration: underline;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  margin-bottom: 10px;
  font-size: 1.1em;
}

em {
  font-size: 0.9em;
  color: #666;
  font-style: normal;
}

.flagged-data-reason {
  color: #d85a60;
}

.small-link {
  font-size: 0.9em;
  /* Adjust the size as needed */
  color: #007BFF;
  text-decoration: none;
  display: block;
  margin-bottom: 10px;
}

.small-link:hover {
  text-decoration: underline;
}

li.clickable {
  cursor: pointer;
  color: #05445e;
  transition: color 0.3s ease, font-weight 0.3s ease;
  font-size: 1em;
  /* Smooth transition */
}

li.clickable:hover {
  color: #189ab4;
  /* Darker color on hover */
  font-weight: bold;
  /* Highlight with bold text */
}