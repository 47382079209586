.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    max-width: 500px;
    margin: 0 auto;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.login-container h1 {
    margin-bottom: 20px;
    color: #333;
}

.login-container input {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.login-error-message {
    color: red;
    margin-bottom: 15px;
}

.login-button-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;
}

.login-button-container button {
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
}

.login-button-container .login-button {
    background-color: #007bff;
}

.login-button-container .logout-button {
    background-color: #6c757d;
}

.login-text-info {
    font-size: 14px;
    color: #555;
    text-align: center;
}

.login-text-info span,
.login-text-info a {
    font-size: 14px;
    /* Ensure the font size is the same */
    color: #007bff;
    cursor: pointer;
    text-decoration: none;
    /* Optional: Remove underline from the link */
}

.login-text-info a:hover {
    text-decoration: underline;
    /* Optional: Add underline on hover */
}