.file-list {
    list-style-type: none;
    padding: 0;
    padding-top: 30px;
    margin: 0;
}

.file-item {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
}

.file-metadata {
    margin-bottom: 5px;
}

.file-name {
    font-weight: bold;
    font-size: 1rem;
    margin: 0;
}

.file-notes-detail {
    font-style: italic;
    color: #6c757d;
    font-size: 0.9rem;
    margin: 0;
}

.file-link {
    text-decoration: none;
    color: #74c3cc;
    font-weight: bold;
}

.file-link:hover {
    text-decoration: underline;
}

.file-icon {
    margin-right: 8px;
    font-size: 1.2rem;
}

.file-delete-icon {
    cursor: pointer;
    color: red;
    margin-left: 10px;
}

.file-section img.uploaded-image {
    width: 100%;
    max-width: 600px;
    height: auto;
    object-fit: contain;
}

.file-notes-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;
    width: 100%;
    /* Adjust as needed */
}

.file-notes-edit {
    flex: 1;
    margin-right: 10px;
}

@media (max-width: 768px) {
    .uploaded-image {
        max-width: 300px;
        /* Smaller image size on smaller screens */
    }
}